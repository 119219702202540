.form_control{
    margin: 10px 0;
    width: 100%;
    display: block;
}

.form_control input, 
.form_control select, 
.form_control textarea{
    border: 1px solid  #d1d1d1;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    
}

.form_control textarea{
    height: 120px;
}