.GalleryPortfolioTumbs{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    overflow: hidden;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.GalleryPortfolioTumbs a{  
    height: 94px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 4px solid #0000001f;
    border-bottom: 4px solid #0000001f;
    border-radius: 20px 0 20px 0;
}

.GalleryPortfolioTumbs a img{
    transition: 0.3s;
}

.GalleryPortfolioTumbs a:hover img{
    transform: scale(1.2);
    transition: 0.3s;
    display: block;
    opacity: 0.6;
}
  
.GalleryPortfolioTumbs img {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    border-radius: 20px 0 20px 0;
    border-top: 4px solid #0000001f;
    border-bottom: 4px solid #0000001f;
  }

.galleryPortfolio img{
    width: 100%;
    height: auto;
    display: block;
}

.lightbox{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99999999;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lighboxOpen{
    display: flex;
}

.lightbox .box{
    max-width: 1170px;
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
    padding: 15px;
    display: flex;
    box-sizing: border-box;
    position: relative;
    align-items: center;
}

.lightbox picture{
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    display: block;
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lightbox picture img{
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    display: table;
    box-sizing: border-box;
    display: block;
}

.centerBox{
    display: flex;
    align-items: center;
    justify-self: center;
}

.centerBox svg{
    color: #FFF;
    font-size: 3em;
    position: relative;
    z-index: 9999;
    cursor: pointer;
}

.close{
    border-radius: 50%;
    background-color: #FFF;
    color: rgb(153, 1, 1);
    font-size: 3em;
    cursor: pointer;
    margin-left: auto;
    display: table;
    z-index: 999999999;
    position: absolute;
    right: 20px;
    top: 10px;
}

@media only screen and (max-width: 768px) {
    .GalleryPortfolioTumbs{
        grid-template-columns: repeat(2, 1fr);
    }
}