.portfolio{
    margin-top: -100px;
    padding: 80px 0;
    background-position: top center;
    background-repeat: no-repeat;
}

.portfolio .btn{
    margin-right: 10px;
}

.grid_portfolio{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    align-items: center;
}

.grid_portfolio div:first-of-type{
    height: 100%;
    display: flex;
    justify-content: space-around;    
}

.portfoliosPage .grid_portfolio > div:first-of-type {
    border-radius: 40px 0 0 0px !important;
}

.grid_portfolio > div:nth-child(2),
.grid_portfolio > div:nth-child(4),
.grid_portfolio > div:nth-child(7){
    border-radius: 40px 0 0 0;
}

.grid_portfolio > div:nth-child(9){
    border-radius: 0 0 40px 0;
}

.grid_portfolio > div:nth-child(3){
    border-radius: 0 40px 0 0;
}

.grid_portfolio > div:nth-child(7){
    border-radius: 0 0 0 40px;
}

.grid_portfolio div{
    position: relative;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.grid_portfolio div .box_portolio_description{
    flex-direction: column;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 15px;
    top: 0;
    left: 0;
    background-color: rgba(33, 47, 57, 0.8);
    -moz-transform: scale(0);
    -webkit-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    transition: 0.2s;
}

.grid_portfolio div:hover .box_portolio_description{
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.grid_portfolio div .box_portolio_description h4{
    color: #FFF;
    margin: 0;
    font-size: 1.2em;
    font-weight: normal;
    font-family: 'century_gothicregular';
}

.grid_portfolio div .box_portolio_description a{
    color: #33f200;
    display: block;
    text-decoration: none;
    margin-top: 10px;
    font-size: 1.2em;
    font-weight: bold;
}

.grid_portfolio img{
    width: 100%;
    display: block;
}

@media only screen and (max-width: 768px) {
    .portfolio{
        padding: 80px 0 40px 0;
    }

    .grid_portfolio {
        display: flex;
        flex-wrap: wrap; 
        gap: 10px;
        justify-content: center !important;
        align-items: left !important;
    }
    
    .grid_portfolio div {
        flex: 1 1 calc(50% - 10px); 
        box-sizing: border-box; 
    }

    .grid_portfolio div:first-of-type {
        display: block;
        gap: 0;
        flex: 100%; 
        box-sizing: border-box; 
    }

    .portfoliosPage .grid_portfolio > div:first-of-type,
    .portfoliosPage .grid_portfolio > div:last-of-type{
        flex: 1 1 calc(50% - 10px) !important; 
    }

    .grid_portfolio > div:nth-child(2),
    .grid_portfolio > div:nth-child(4),
    .grid_portfolio > div:nth-child(6),
    .grid_portfolio > div:nth-child(8),
    .portfoliosPage .grid_portfolio > div:nth-child(odd),
    .portfoliosPage .grid_portfolio > div:first-of-type {
        border-radius: 40px 0 0 40px !important;
    }

    .grid_portfolio > div:nth-child(3), 
    .grid_portfolio > div:nth-child(5),
    .grid_portfolio > div:nth-child(7),
    .grid_portfolio > div:nth-child(9),
    .portfoliosPage .grid_portfolio > div:nth-child(even){
        border-radius: 0 40px 40px 0 !important;
    }

    .grid_portfolio div .box_portolio_description{
        display: block;
        position: relative;
        background-color: #212f39;
        height: auto;width: auto;
        transform: scale(1);
    }

    .grid_portfolio div .box_portolio_description h4{   
        overflow: hidden;
        font-size: 1em;
        display: -webkit-box;
        -webkit-line-clamp: 2; 
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;            
        text-overflow: ellipsis;     
        max-height: 3em;
        line-height: 1.5em; 
        min-height: 48px; 
    }

    .grid_portfolio div .box_portolio_description a{
        font-size: 1em;
    }
}