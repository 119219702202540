.header{
    background-color: #006d3a;
    z-index: 9999;
    position: relative;
}

.header:after{
    background-color: #FFF;
    position: absolute;
    z-index: 99;
    content: "";
    display: block;
    height: 100%;
    width: 40vw;
    top: 0;
    right: 0;
}

.header .logo{
    max-width: 200px;
    display: block;
    padding: 8px 20px;
}

.header .menu nav{
    height: 100%;
    display: flex;
    align-items: center;
}

.header .menu{
    background-color: #FFF;
    border-radius: 0 0 0 50px;
    width: 100%;
    padding: 0px 20px 0px 40px;
    display: block;
    z-index: 9999999;
}

.header .menu nav > ul{
    margin: 0px;
    padding: 0px;
    display: flex;
    list-style: none;
    align-items: center;
    height: 100%;
}

.header .menu nav > ul li a{
    display: block;
    padding: 16px 6px 16px 20px;
    margin-right: 20px;
    font-size: 1em;
    font-weight: bold;
    display: flex;
}

.header .menu nav > ul > li a:after{
    margin-left: 6px;
    width: 3px;
    height: 28px;
    content: "";
    display: block;
}

.header .menu nav > ul li a.active:after,
.header .menu nav > ul li:hover a:after{
    background-color: #00b63e;
}

.header .menu nav ul ul li a:after{
    display: none !important;
}

.header .menu nav > ul li{
    position: relative;
}

.header .menu nav ul ul{
    display: none;
    transition: 0.5s;
    position: absolute;
    top: 60px;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 9999;
    height: auto;
    border-radius: 0 0 20px 20px;
    background-color: #FFF;
    overflow: hidden;
}

.header .menu nav ul li:hover ul{
    display: block;
}

.header .menu nav ul ul a{
    font-size: 0.9em;
    line-height: 1.4em;
    padding: 10px 20px;
    margin: 0;
    font-weight: normal;
    background-color: #FFF;
    color: #00b63e;
    width: 300px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}

.header .menu nav ul ul li:last-child a{
    border: none;
}

.header .menu nav ul ul a:hover{
    color: #FFF;
    background-color: #00b63e;
}


/* mobile */
@media only screen and (max-width: 768px) {

    .header:after{
        width: 20vw;
    }

    header > div{
        flex-direction: row !important;
    }

    .header .menu{
        max-width: 70px;
    }

    .menu_toggle{
        width: 35px;
        box-sizing: border-box;
        display: block;
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        z-index: 99999;
    }

    .menu_toggle span{
        margin: 0;
        padding: 4px 0;
        display: block;
        transition: 0.3s;
    }

    .menu_toggle span:after{
        background-color: #00b63e;
        height: 3px;
        width: 100%;
        display: block;

        border-radius: 10px;
        transition: 0.3s;
        content: "";
    }

    .menuOpen span:after{
        opacity: 0;
        transition: 0.3s;
    }

    .menuOpen span:first-child:after{
        opacity: 1;
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .menuOpen span:last-child:after{
        opacity: 1;
        -moz-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .menuOpen span:first-child{
        -moz-transform: translateY(10px);
        -webkit-transform: translateY(10px);
        -o-transform: translateY(10px);
        -ms-transform: translateY(10px);
        transform: translateY(10px);
    }

    .menuOpen span:last-child{
        -moz-transform: translateY(-11px);
        -webkit-transform: translateY(-11px);
        -o-transform: translateY(-11px);
        -ms-transform: translateY(-11px);
        transform: translateY(-11px);
    }

    .menuCloseItens{
        right: -500px;
    }

    .menuOpenItens{
        right: 0;
    }

    .header .menu nav .first_menu{
        display: flex !important;
        flex-direction: column;
        position: absolute;
        top: 0;
        width: 30vw;
        min-width: 100vw;
        height: 100vh !important;
        overflow: auto;
        background-color: #FFF;
        padding-top: 50px !important;
        transition: 1s;
        text-align: left;
        align-items: center;
    }

    .header .menu nav .first_menu ul{
        display: block !important;
        transition: 0.6s;
        position: relative !important;
        margin-top: 0;
        top: 0;
        box-sizing: border-box;
        margin: 0;
        margin-bottom: 14px;
        padding: 0 20px;
    }

    .header .menu nav .first_menu li{
        width: 100%;
        text-align: left;
        box-sizing: border-box;
    }

    .header .menu nav .first_menu li a{
        margin: 0px;
        padding: 6px 20px;
        box-sizing: border-box;
        border-radius: 10px 0 10px 0;
    }

    .header .menu nav .first_menu ul li a{
        padding: 8px 20px;
        box-sizing: border-box;
    }

    .menu_toggle{
        display: block;
    }

    .header .menu nav ul.first_menu{
        display: none;
    }

    .header .menu nav .first_menu{
        scrollbar-width: thin; /* Firefox */
        scrollbar-color: #00b63e #f1f1f1; /* Firefox */
    }

    .header .menu nav .first_menu::-webkit-scrollbar {
        width: 12px;
    }
      
    .header .menu nav .first_menu::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
      
    .header .menu nav .first_menu::-webkit-scrollbar-thumb {
        background: #00b63e;
        border-radius: 6px;
    }
      
    .header .menu nav .first_menu::-webkit-scrollbar-thumb:active {
        background: #007e2a;
    }
      
    .header .menu nav .first_menu::-webkit-scrollbar-thumb:hover {
        background: #009d34;
    }
}

/* desktop */
@media only screen and (min-width: 769px) {
    .menu_toggle{
        display: none;
    }

    .header .menu nav ul.first_menu{
        display: flex;
    }
}