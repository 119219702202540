.h1{
    background-color: #f3f3f3;
    padding: 50px 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'century_gothicbold';
    color: #FFF;
    background-image: url(../../images/bg-quadriculado.jpg);
    background-position: center;
}

.h1 h1{
    font-size: 2.4em;
    line-height: 1.2em;
    margin: 0;
    padding: 0;
    color: #FFF;
}