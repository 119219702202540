.boxSuccess, 
.boxError{
    background-color: #00b63e;
    color: #FFF;
    border-radius: 40px 0 40px 0;
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
}

.boxError{
    background-color: rgb(167, 1, 1);
}

.boxSuccess p,
.boxError p{
    margin: 0 !important;
    padding: 0 !important;
}