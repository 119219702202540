.company_text{
    width: 33%;
    padding: 15px;
}

.contacts{
    width: 40%;
    padding: 15px 30px;
}

.social{
    width: 20%;
    padding: 15px;
}

.company_text img{
    max-width: 200px;
}

.contacts ul, .social ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.contacts ul li, .social ul li{
    padding: 10px 0;
}

.social ul{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
}

.social ul li a svg{
    font-size: 2em;
    transition: 0.3s;
}

.social ul li a:hover svg, .contacts a:hover{
    color: #00b63e;
}

.modules h3{
    font-size: 1.4em;
    margin-top: 0;
    margin-bottom: 10px;
    color: #00b63e;
}

.modules > div{
    background-color: #000;
    font-size: 0.95em;
}

.modules, .rights{
    color: #151515;
    position: relative;
}

.modules > div{
    background-color: #e8e8e8;
    border-radius: 20px 0 0 0;
    z-index: 2;
    position: relative;
}

.footer{
    position: relative;
}

.footer:before{
    z-index: 1;
    position: absolute;
    right: 0;
    background-color:#e8e8e8;
    content: "";
    display: table;
    width: 50vw;
    height: 100%;
}

.footer a{
    color: #151515;
    text-decoration: none;
    transition: 0.3s;
}

.footer .rights a:hover{
    text-decoration: underline;
}

.rights > div{
    color: #FFF;
    background-color: #006d3a;
    border-radius: 0 20px 0 0;
    z-index: 2;
    position: relative;
    font-size: 0.8em;
}

.rights > div a{
    color: #FFF;
}

.rights > div div{
    padding: 0 15px;
}

.rights:before{
    z-index: 1;
    position: absolute;
    background-color: #006d3a;
    content: "";
    display: table;
    width: 50vw;
    height: 100%;
}

@media only screen and (max-width: 768px) {

    .rights{
        margin-right: 10px;
    }

    .modules{
        margin-left: 10px;
    }

    .company_text, .contacts, .social{
        width: 100%;
        box-sizing: border-box;
        text-align: center;
    }

    .company_text img{
        margin: 0 auto;;
    }

    .rights{
        text-align: center;
    }
}