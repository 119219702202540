.grid{
    display: grid;
    max-width: 1170px;
    margin: 0 auto;
    padding: 40px 15px;
}

.grid.colunms_3{
    grid-template-columns: repeat(3, 1fr);
    gap: 60px 40px;
}

.paddingSection{
    padding: 80px 15px;
}

@media only screen and (max-width: 768px) {
    .paddingSection{
        padding: 40px 15px;
    }

    .grid.colunms_3{
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }    
}