@font-face {
  font-family: 'century_gothicregular';
  src: url('./assets/fonts/century-gothic-webfont.woff2') format('woff2'),
       url('./assets/fonts/century-gothic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'century_gothicbold';
  src: url('./assets/fonts/gothicb-webfont.woff2') format('woff2'),
       url('./assets/fonts/gothicb-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html, body, #root{
  margin: 0;
  padding: 0;
  font-family: 'century_gothicregular';
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 1em;
  line-height: 1.8em;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

nav ul ul a.active,
nav > ul li a.active:after{
    background-color: #00b63e !important;
}

nav ul ul a.active{
  color: #FFF !important;
}

.bg_gray {
  background-color: #f1f1f1;
  padding: 30px;
  margin: 30px 0;
}

a{
  color: #00b63e;
  text-decoration: none;
}

p a:hover{
  text-decoration: underline;
}

p{
  font-size: 0.9em;
}

.width_100_percent{
  width: 100%;
}

.text-center{
  text-align: center;
}

.ml-auto{
  margin-left: auto;
}

.mr-auto{
  margin-right: auto;
}

.mr-10{
  margin-right: 10px;
}

.btn-round-right{
  display: table;
  margin-left: auto;
  margin-top: 20px;
  text-decoration: none;
  color: #FFF;
  background-color: #00b63e;
  border-radius: 0 40px 0 40px;
  padding: 10px 36px;
}

.btn{
  position: relative;
  overflow: hidden;
}

.btn span{
  position: relative;
  z-index: 2;
}

.btn:after{
  width: 0;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  transition: 0.3s;
  background-color: #016924;
  z-index: 1;
}

.btn:hover:after{
  width: 100%;
  transition: 0.3s;
}

img{
  display: block;
}

h1, h2, h3, h4, h5, h6{
  color: #006d3a;
  font-family: 'century_gothicbold';
  font-weight: normal;
}

.title:after{
  content: "";
  display: block;
  background-color: #00b63e;
  height: 100%;
  width: 4px;
  margin-left: 20px;
}

.title{
  font-size: 2rem;
  line-height: 1.2em;
  display: flex;
  position: relative;
  align-items: center;
  width: 250px;
  min-width: 250px;
  margin-right: 40px;
  justify-content: space-between;
}

.title-big{
  font-size: 2em;
}

.font-light{
  font-family: 'century_gothicregular';
}

b, strong{
  font-family: 'century_gothicbold';
}

.alice-carousel li{
  padding: 15px;
}

img{
  width: 100%;
  height: auto;
}

.alice-carousel{
  padding-top: 10px;
}

.alice-carousel p{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

li.alice-carousel__stage-item {
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -o-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  transition: 0.2s;
}

.alice-carousel__stage-item.__target + .alice-carousel__stage-item {
  transform: scale(1);
}

.services .item, li.alice-carousel__stage-item .item {
  background-color: #212f39;
  color: #FFF;
  padding: 20px;
  border-radius: 40px 0 40px 0;
}

.services .item{
  display: grid;
}

.services .item img, li.alice-carousel__stage-item .item img{
  margin-left: -30px;
  margin-top: -30px;
  width: calc(100% + 40px);
  border-radius: 40px 0 0 0;
  display: block;
}

.services .item a, li.alice-carousel__stage-item .item a{
  display: table;
  margin-left: auto;
  margin-right: -20px;
  margin-bottom: -20px;
  margin-top: 20px;
  text-decoration: none;
  color: #FFF;
  background-color: #00b63e;
  border-radius: 0 0 40px 0;
  padding: 10px 36px;
}

.alice-carousel__prev-btn, 
.alice-carousel__next-btn{
  background-color: transparent;
  position: absolute;
  top: -65px;
}

.alice-carousel__prev-btn{
  left: 0;
  width: 28% !important;
}

.alice-carousel__next-btn{
  right: 0;
  width: 72% !important;
}

.alice-carousel__prev-btn button, 
.alice-carousel__next-btn button{
  background-color: transparent;
  border: 0;
  padding: 0;
  color: #00b63e;
  cursor: pointer;
  transition: 0.3s;
}

.alice-carousel__prev-btn button:hover, 
.alice-carousel__next-btn button:hover{
  transform: scale(1.2);
}

.alice-carousel__prev-btn button svg, 
.alice-carousel__next-btn button svg{
  display: block;
}

#portfoliosItems > div{
    border-radius: 0;
}

#portfoliosItems > div:nth-child(3){
    border-radius: 0 40px 0 0;
}

form input:focus, 
form input:focus-visible{
  border-color: #00b63e;
}

.form .btn{
  border: 0;
  cursor: pointer;
  transition: 0.3s;
  font-size: 1em;
}

.form .btn:hover{
  transform: scale(1.1);
  transition: 0.3s;
}

.mapEmbed iframe{
  border: 0;
}

.main{
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
  display: table;
}

#servicePage .main img{
  display: table;
  float: left;
  max-width: 450px;
  margin-right: 20px;
  margin-bottom: 2px;
  border-radius: 40px 0 40px 0;
  border-top: 4px solid #0000001f;
  border-bottom: 4px solid #0000001f;
}

#servicePage .main p:first-of-type{
  margin-top: -8px;
}

#portfolioPage picture.imgMain{
  border-radius: 40px 0 40px 0;
  display: block;
  overflow: hidden;
  max-width: 500px;
  width: 50%;

}

#portfolioPage picture img{
  border-top: 4px solid #0000001f;
  border-bottom: 4px solid #0000001f;
  border-radius: 40px 0 40px 0;
}

#portfolioPage .textPortfolio{
  max-width: 50%;
}

.portfolioInformations{
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.portfolioInformations b{
  color: #00b63e;
}

.portfolioInformations p{
  margin-top: -8px;
}

.width50percent{
  width: 50%;
  box-sizing: border-box;
}

.width50percent:nth-child(2){
  box-sizing: border-box;
  width: 45%;
  margin-left: 5%;
}

.contactPage h3{
  margin-bottom: 0;
}

.contactPage .width50percent:nth-child(2) p{
  margin-top: 0;
  margin-bottom: 30px;
}

.flexForm{
  gap: 0 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.flexForm input{
  width: 100%;
}

.formFiles{
  gap: 0 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.formFiles input{
  border: 0 !important;
}

@media only screen and (max-width: 768px) {

  .flexForm, .formFiles{
    grid-template-columns: repeat(1, 1fr);
  }

  .contactPage .width50percent:nth-child(2){
    margin-top: 40px;
  }

  .width50percent:nth-child(2){
    margin-left: 0;
  }

  .width50percent,
  #portfolioPage .textPortfolio,
  #portfolioPage picture.imgMain{
    max-width: 100% !important;
    width: 100% !important;
  }

  .portfolioInformations p{
    margin: 30px 0;
  }

  #servicePage .main img{
    margin-bottom: 20px;
  }

  li.alice-carousel__stage-item {
    transform: scale(1);
    transition: 0.2s;
  }

  .title{
    min-height: 80px;
    height: 100%;
    justify-content: flex-start;
  }

  .title:after{
    min-height: 80px;
  }
}
