.container{
    max-width: 1170px;
    padding: 15px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.container.block{
    display: block;
}

.container.center{
    align-items: center;
}

.paddingSection{
    padding: 80px 15px;
}

.padding{
    padding: 40px 15px;
}

.paddingZero{
    padding: 0px 15px;
}

@media only screen and (max-width: 768px) {
    .container{
        flex-direction: column;
    }

    .paddingSection{
        padding: 40px 15px;
    }
}