.breadcrumbs{
  background-color: #fcfcfc;
  border-top: 1px solid #0000001c;
  border-bottom: 1px solid #0000001c;
}

.breadcrumbs > div{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.breadcrumbs ul{
  list-style: none;
  display: flex;
  margin: 0;
  padding: 8px 0;
  font-size: 0.9em;
}

.breadcrumbs li {
  margin-right: 1rem;
  display: flex;
}

.breadcrumbs li::after {
  content: '>';
  margin-left: 1rem;
  color: #777777;
}

.breadcrumbs li:last-child::after {
  content: '';
}

.breadcrumbs li a{
  display: inline-block;
}

.breadcrumbs li a:hover{
  text-decoration: underline;
}
  