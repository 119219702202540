
.services{
    background-image: url(../../images/bg-quadriculado.jpg);
    margin-top: 60px;
    padding-bottom: 0px;
    border-radius: 30px 0 0 0;
    position: relative;
}

.services:after{
    content: "";
    width: 100%;
    height: 200px;
    display: block;
    background-color: #FFF;
    clip-path: polygon( 98.854% -0.172%,98.854% -0.172%,98.968% -0.172%,99.083% -0.172%,99.198% -0.172%,99.312% -0.172%,99.427% -0.172%,99.542% -0.172%,99.656% -0.172%,99.771% -0.172%,99.885% -0.172%,100% -0.172%,100% -0.172%,100% 9.845%,100% 19.862%,100% 29.88%,100% 39.897%,100% 49.914%,100% 59.931%,100% 69.948%,100% 79.966%,100% 89.983%,100% 100%,100% 100%,89.997% 100%,79.995% 100%,69.992% 100%,59.99% 100%,49.987% 100%,39.984% 100%,29.982% 100%,19.979% 100%,9.977% 100%,-0.026% 100%,-0.026% 100%,-0.026% 99.966%,-0.026% 99.931%,-0.026% 99.897%,-0.026% 99.862%,-0.026% 99.828%,-0.026% 99.793%,-0.026% 99.759%,-0.026% 99.725%,-0.026% 99.69%,-0.026% 99.656%,-0.026% 99.656%,0.414% 97.643%,0.861% 95.699%,1.313% 93.824%,1.773% 92.017%,2.238% 90.279%,2.71% 88.609%,3.189% 87.008%,3.674% 85.476%,4.165% 84.012%,4.663% 82.616%,4.663% 82.616%,5.743% 79.874%,6.829% 77.244%,7.92% 74.726%,9.016% 72.32%,10.118% 70.027%,11.225% 67.845%,12.338% 65.776%,13.456% 63.819%,14.579% 61.974%,15.707% 60.241%,15.707% 60.241%,18.06% 56.849%,20.416% 53.607%,22.777% 50.514%,25.141% 47.571%,27.51% 44.776%,29.882% 42.132%,32.258% 39.636%,34.639% 37.29%,37.023% 35.094%,39.411% 33.046%,39.411% 33.046%,43.464% 29.741%,47.52% 26.596%,51.577% 23.612%,55.637% 20.788%,59.7% 18.126%,63.765% 15.625%,67.832% 13.285%,71.901% 11.105%,75.973% 9.087%,80.047% 7.229%,80.047% 7.229%,81.929% 6.403%,83.811% 5.595%,85.692% 4.807%,87.574% 4.039%,89.454% 3.289%,91.335% 2.558%,93.215% 1.847%,95.095% 1.155%,96.975% 0.482%,98.854% -0.172% );
}

.services:before{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: "";
    display: block;
    background-color: #FFF;
}

.services h3{
    margin-left: auto;
    display: table;
    font-size: 2rem;
    margin-top: -50px;
}

.services h4{
    font-size: 1.2rem;
    color: #FFF;
    margin-bottom: 0;
}

.services svg{
    background-color: #00b63e;
    border-radius: 50%;
    color: #FFF;
    border-radius: 50%;
    border: 3px solid #FFF;
    text-align: center;
    font-size: 3.4em;
}

@media only screen and (max-width: 768px) {
    .services:after{
        height: 100px;
    }
}