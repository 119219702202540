.main, 
.main video, 
.content,
.overlay {
    height: calc(100vh - 61px);
}

.main {
    width: 100%;

    overflow: hidden;
    box-sizing: border-box;
    position: relative;
}

.main video {
    width: 100%;
    object-fit: cover;
  }
  
.content {
    position: absolute;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    color: white;
    padding: 0 10vw;
    max-width: 750px;
    box-sizing: border-box;
    opacity: 1;
    transition: 0.5s;
}

.content.fade{
    opacity: 0;
    transition: 0.5s;
}

.content h1, .content h2{
    color: #FFF;
    font-size: 3em;
    margin-bottom: 10px;
}

.content p{
    margin-bottom: 10vh;
}
  
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0,0,0,.4);
}