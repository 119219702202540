.banner_home{
    background-image: url(../../images/pavimentacao.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
    position: relative;
}

.about_us a{
    display: block;
    text-align: right;
    margin-top: 10px;
    font-family: 'century_gothicbold';
}

.clients, .about_us{
    padding: 80px 0px;
}

.clients a{
    display: block;
    transition: 0.3s;
}

.clients a:hover{
    transform: scale(1.1);
    transition: 0.3s;
}

.clients h2, .about_us h2{
    width: 33%;
    padding-right: 30px;
}

.clients .grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px 70px;
    margin-left: auto;
}


@media only screen and (max-width: 768px) {

    .clients, .about_us{
        padding: 40px 0px;
    }

    .clients h2, .about_us h2{
        width: auto;
        box-sizing: border-box;
    }

    .clients .grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px 20px;
    }
}